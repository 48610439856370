<template>
    <div class="introduction">
        <div class="top-wrapper">
            <img class="top-img" src="@/assets/imgs/introduction.png" alt="">
            <div class="mask">
                <img class="m-img" src="@/assets/imgs/id-icon.png" alt="">
                <div class="m-title">体验720°数字博物馆</div>
                <div class="btn cursor-pointer" @click="handleClick()">开始体验</div>
            </div>
        </div>
        <div class="container">
            <div class="content">
                <div class="left">
                    <div class="title">【博物馆简介】</div>
                    <div class="desc">松溪县博物馆成立于2000年7月，坐落于松溪文化广场北端，2012年7月建成。占地面积900m2，建筑面积2600m2，展厅面积560m2，一楼为综合展览，二楼是历史文物陈列。馆内还设有库房、修复室、摄影室、多功能报告厅、办公室等业务功能场所。是福建省三级博物馆、南平市爱国主义教育基地。
                    </div>
                </div>
                <div class="right">
                    <img class="content-img-1" src="@/assets/imgs/id-2.png" alt="">
                </div>
            </div>
            <div class="content">
                <div class="left">
                    <img class="content-img-2" src="@/assets/imgs/id-1.png" alt="">
                </div>
                <div class="right">
                    <div class="desc">
                        <p class="m-b-15">松溪县博物馆是集陈列展览、文物收藏、学术研究、社会教育于一体的地方综合性历史博物馆。馆藏登记入册的文物1668件（套），其中珍贵文物三级513件（套）、二级3件。馆藏文物年代跨度从青铜时代到民国时期，种类有银、铜、铁、玉、石、陶、瓷、绫、纸、墓砖、木器等。</p>
                        <p>松溪县博物馆完成了可移动文物预防性保护、数字化保护利用项目建设。一楼数字化互动魔墙提升了参观体验，延展了展览空间，二楼通史陈列展览突出松溪特色的文物：始于北宋天圣年间回场窑出土的青瓷，是我国宋代海上丝绸之路贸易的外销青瓷，七件西门窑址出土的代表性青瓷选调晋京入故宫展出；南宋开禧元年的仪制令石刻路碑为全国特有，是我国古代交通礼仪文明规范推行的最早物证；在湛卢山麓出土的“龟山书室”石刻香炉，见证了程朱理学文化在松溪传承发扬；明万历、天启年间朝廷颁给重臣真宪时七道圣旨的绫地绣巨幅中堂，为镇馆文物。</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    const handleClick =()=>{
        // window.location.href='https://www.qofo360.com/jinlin/sxbwg/index.html'
        window.open('https://www.qofo360.com/jinlin/sxbwg/index.html', '_blank');

    }
</script>

<style lang="scss" scoped>
.introduction {
    .top-wrapper{
        position: relative;
        .mask{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,.5);
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .m-title{
                font-family:sy-font,serif;
                font-weight:700;
                color: rgba(255, 255, 255, 0.60);
                font-size:53px;
                margin-top: 37px;
            }
            .m-img{
                width: 117px;
                height: 117px;
            }
            .btn{
                width:336px;
                height:94px;
                border: 3px solid rgba(255, 255, 255, 0.54);
                border-radius:48px;
                text-align: center;
                line-height: 94px;
                margin-top: 35px;
                font-weight:700;
                color:#ffffff;
                font-size:38px;
            }
        }
    }
    .top-img {
        width: 100%;
    }
    .container{
        margin-top: 131px;
        margin-bottom: 217px;
    }
    .content{
        margin-bottom: 65px;
        display: flex;
        justify-content: space-between;
        line-height:36px;
        .left{
            width: 554px;
            .title{
                font-family:sy-font,serif;
                font-weight:700;
                color:#1c1c1c;
                font-size:31px;
            }

        }
        .right{
            width: 474px;
        }
        .desc{
            width: 100%;
            margin-top: 50px;
            font-family:Source Han Sans CN;
            color: rgba(28, 28, 28, 0.5);
            font-size:16px;
            text-indent: 30px;
            text-align: justify;
            .m-b-15{
                margin-bottom: 15px;
            }
        }
        .content-img-1{
            width: 474px;
        }
        .content-img-2{
            width: 554px;
            margin-top: 105px;
        }
    }
}
</style>
